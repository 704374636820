<div class="container">
    <div class="d-flex justify-content-center align-items-center">
        <div class="card login-form-container bg-white">
            <div class="card-title border-bottom">
                <!-- Logo -->
                <div class="d-flex justify-content-center align-items-center">
                    <img src="/assets/logos/logo-wide.png" alt="logo" class="logo my-5">
                    <!-- <img src="https://www.sensecure.in/img/Sensecure%20logo.png" alt="logo" class="logo my-5"> -->
                </div>
            </div>
            <div class="card-body p-5">
                <form #loginForm="ngForm" (ngSubmit)="submitLogin(loginForm)">
                    <div class="form-group mb-3">
                        <label for="email">Email</label>
                        <input type="text" class="form-control" [(ngModel)]="login.email" name="email" id="email" autocomplete="email" required>
                        <span class="invalid-feedback">
                            Email is required
                        </span>
                    </div>
                    <div class="form-group mb-3">
                        <label for="password">Password</label>
                        <input type="password" class="form-control" [(ngModel)]="login.password" name="password" id="password" autocomplete="current-password" required>
                        <span class="invalid-feedback">
                            Password is required
                        </span>
                    </div>
                    <button type="submit" class="btn btn-lg btn-primary w-100 mt-3">Login</button>
                </form>
                <span class="invalid-feedback">
                    Invalid email or password
                </span>
                <!-- <div class="mt-1">
                    <a routerLink="/forgot-password" class="c-primary fw-bold">Forgot Password?</a>
                </div> -->
                <div class="mt-1">
                    Don't have an account? <a routerLink="/sign-up" class="c-primary fw-bold">Sign Up</a>
                </div>
            </div>
        </div>
    </div>
</div>