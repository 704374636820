<div class="container">
    <div class="d-flex justify-content-center align-items-center">
        <div class="card w-100 m-5">
            <div class="card-body text-center p-5">
                <div class="display-3 mb-3">
                    404 - Page Not Found
                </div>
                <div class="fs-4 text-muted fw-light">
                    Sorry! Cannot seem to find the page you were looking for.
                </div>
            </div>
        </div>
    </div>
</div>
