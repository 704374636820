<!-- modal for add/update -->
<div class="modal fade" [ngClass]="{'show': isEdit}" id="addUpdateMasterModal" tabindex="-1" aria-labelledby="addUpdateMasterModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="addUpdateMasterModalLabel">{{ isEdit == 'Update' ? 'Update' : 'Add' }} {{ selectedMasterType }}
                </h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="isEdit=undefined"></button>
            </div>
            <div class="modal-body">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <label for="masterName" class="form-label">Name</label>
                                <input id="masterName" class="form-control" [(ngModel)]="masterToAddOrEdit.name" name="masterName"
                                    type="text" placeholder="Name" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="isEdit=undefined">Close</button>
                <button
                    *ngIf="isEdit == 'Add'"
                    type="button" class="btn btn-primary" (click)="addNewItem()">
                    Add
                </button>
                <button
                    *ngIf="isEdit == 'Update'"
                    type="button" class="btn btn-primary" (click)="editItem(masterToAddOrEditIndex)">
                    Update
                </button>
            </div>
        </div>

    </div>
</div>
<!-- masters -->

<div class="row">
    <div class="col-12 col-md">
        <div class="row">
            <div class="col">
                <!-- Date time card -->
                <div class="card bg-white">
                    <div class="card-header">
                        <h4 class="mb-0">MASTERS</h4>
                    </div>
                    <div class="card-body">
                        <!-- Masters -->
                        <div class="container">
                            <!-- selectable chips/badges for masters -->
                            <div class="row">
                                <div class="col-12">
                                    <div class="d-flex flex-column flex-md-row justify-content-between">
                                        <div class="d-flex flex-wrap mb-2 mb-md-0">
                                            <div *ngFor="let masterType of mastersTypes; let i = index" class="me-2 mb-2">
                                                <button class="btn btn-outline-primary w-100" [ngClass]="{
                                                        'active': selectedMasterType === masterType.title
                                                    }" (click)="selectMasterType(masterType.title)">
                                                    <i class="fi {{ masterType.icon }}"></i>
                                                    <span class="ms-2">{{ masterType.title }}</span>
                                                </button>
                                            </div>
                                        </div>
                                        <div>
                                            <button class="btn btn-primary w-100 w-md-auto" data-bs-toggle="modal" data-bs-target="#addUpdateMasterModal"
                                            (click)="selectItemToAdd()">
                                                <i class="fi fi-rr-add"></i>
                                                <span class="ms-2">
                                                    Add {{ selectedMasterType }}
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-12">
                                    <div class="table-responsive">
                                        <table class="table table-hover table-striped">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Name</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of data; let i = index">
                                                    <td>{{ i + 1 }}</td>
                                                    <td>{{ item.name }}</td>
                                                    <td>
                                                        <button class="btn btn-outline-primary btn-sm"
                                                            (click)="selectItemToEdit(i)">
                                                            <i class="fi fi-rr-pencil"></i>
                                                        </button>
                                                        <button class="btn btn-outline-danger btn-sm"
                                                            (click)="deleteItem(i)">
                                                            <i class="fi fi-rr-trash"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>