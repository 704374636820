<!-- Stock Modal -->
<div class="modal fade" [ngClass]="{'show': isStockUpdateModelOpen}" id="addUpdateStockModal" tabindex="-1"
    aria-labelledby="addUpdateStockModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="addUpdateStockModalLabel">Update Stock</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                    (click)="isStockUpdateModelOpen=false"></button>
            </div>
            <div class="modal-body">
                <div class="container">
                    <!-- Form:
                    stockItemToAddOrEdit.crop: crops dropdown,
                    stockItemToAddOrEdit.quantity: in quintals,
                    stockItemToAddOrEdit.warehouse: warehouses dropdown,
                    stockItemToAddOrEdit.price: in ₹,
                    stockItemToAddOrEdit.logType: 'purchase', 'sale', 'transfer in', 'transfer out',
                    stockItemToAddOrEdit.meta_data.remark: text,
                    -->
                    <div class="row">
                        <div class="col-12">
                            <!-- <div class="form-group mb-3">
                                <label for="crop" class="form-label">Crop</label>
                                <select id="crop" class="form-select" [(ngModel)]="stockItemToAddOrEdit.crop"
                                    name="crop">
                                    <option value="">Select Crop</option>
                                    <option *ngFor="let crop of crops" [value]="crop._id">{{ crop.name }}</option>
                                </select>
                            </div> -->
                            <div class="row">
                                <div class="col">
                                    <div class="form-group mb-3">
                                        <label for="no_of_bags" class="form-label">No of Bags</label>
                                        <input id="no_of_bags" class="form-control" name="no_of_bags" type="number"
                                            placeholder="No of Bags" />
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group mb-3">
                                        <label for="unit_weight_of_bags" class="form-label">Unit Weight of Bags (in
                                            Kgs)</label>
                                        <input id="unit_weight_of_bags" class="form-control" name="unit_weight_of_bags"
                                            type="number" placeholder="Unit Weight of Bags" />
                                    </div>
                                </div>
                                <div class="col-auto">
                                    <div class="form-group mb-3">
                                        <label for="add_button" class="form-label">Add</label>
                                        <button id="add_button" class="btn btn-primary w-100" (click)="addBags()">
                                            Add
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <table class="table table-hover table-striped">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>No of Bags</th>
                                        <th>Weight (in Kgs)</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let bag of stockItemToAddOrEdit.bag_units; let i = index">
                                        <td>{{ i + 1 }}</td>
                                        <td>{{ bag.no_of_bags }}</td>
                                        <td>{{ bag.unit_weight_of_bags }}</td>
                                        <td>
                                            <button class="btn btn-outline-danger btn-sm" (click)="removeBag(i)">
                                                <i class="fi fi-rr-cross"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="form-group mb-3">
                                <label for="quantity" class="form-label">Quantity (In Quintals)</label>
                                <input id="quantity" class="form-control" [(ngModel)]="stockItemToAddOrEdit.quantity"
                                    name="quantity" type="number" placeholder="Quantity" readonly />
                            </div>
                            <!-- <div class="form-group mb-3">
                                <label for="warehouse" class="form-label">
                                    Warehouse (Storage Location)
                                </label>
                                <select id="warehouse" class="form-select" [(ngModel)]="stockItemToAddOrEdit.warehouse"
                                    name="warehouse">
                                    <option value="">Select Warehouse</option>
                                    <option *ngFor="let warehouse of warehouses" [value]="warehouse._id">
                                        {{ warehouse.name }}
                                    </option>
                                </select>
                            </div> -->
                            <div class="form-group mb-3">
                                <label for="price" class="form-label">Rate (in ₹)</label>
                                <input id="price" class="form-control" [(ngModel)]="stockItemToAddOrEdit.price"
                                    name="price" type="number" placeholder="Price" readonly/>
                            </div>
                            <div class="form-group mb-3">
                                <label for="logType" class="form-label">Log Type</label>
                                <select id="logType" class="form-select" [(ngModel)]="stockItemToAddOrEdit.logType"
                                    name="logType">
                                    <option value="">Select Log Type</option>
                                    <option value="purchase">Purchase</option>
                                    <option value="sale">Sale</option>
                                    <option value="transfer in">Transfer In</option>
                                    <option value="transfer out">Transfer Out</option>
                                </select>
                            </div>
                            <div class="form-group mb-3">
                                <label for="remark" class="form-label">Remark</label>
                                <textarea id="remark" class="form-control"
                                    [(ngModel)]="stockItemToAddOrEdit.meta_data.remark" name="remark"
                                    placeholder="Remark"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                    (click)="isStockUpdateModelOpen=false">Close</button>
                <button type="button" class="btn btn-primary" (click)="saveStockItem()">Update</button>
            </div>
        </div>
    </div>
</div>

<div class="row mt-3">
    <div class="col-12">
        <div class="card bg-white">
            <div class="card-header">
                <div class="d-flex justify-content-center align-items-center">
                    <h2>
                        Stock View
                    </h2>
                </div>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-12">
                        <table class="table table-hover table-striped">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Crop</th>
                                    <th>Quantity (in Quintals)</th>
                                    <th>Warehouse</th>
                                    <th>Price (in ₹)</th>
                                    <th>Log Type</th>
                                    <th>Remark</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngIf="stock_data.length === 0">
                                    <td colspan="8" class="text-center">No data available</td>
                                </tr>
                                <tr
                                    *ngFor="let stock of stock_data | paginate: { id:'stock-pagination', itemsPerPage: limit, currentPage: page, totalItems: total }; let i = index">
                                    <td>{{ i + 1 + (page - 1) * 10 }}</td>
                                    <td>{{ stock.crop?.name || 'N/A' }}</td>
                                    <td>{{ stock.quantity }}</td>
                                    <td>{{ stock.warehouse?.name || 'N/A' }}</td>
                                    <td>{{ stock.price }}</td>
                                    <td>{{ stock.logType }}</td>
                                    <td>{{ stock.meta_data?.remark || 'N/A' }}</td>
                                    <td>
                                        <button *ngIf="!stock.bag_units.length" class="btn btn-outline-primary btn-sm"
                                            (click)="openStockUpdateModel(stock)">
                                            <i class="fi fi-rr-edit"></i>
                                        </button>
                                        <a *ngIf="stock.meta_data?.taulParchi" class="btn btn-outline-success btn-sm"
                                            [routerLink]="['/taul-parchi-view/'+stock.meta_data.taulParchi]">
                                            <i class="fi fi-rr-eye"></i>
                                            View Taul Parchi
                                        </a>
                                        <a *ngIf="stock.meta_data?.truckLoading" class="btn btn-outline-success btn-sm"
                                            [routerLink]="['/truck-loading-parchi-view/'+stock.meta_data.truckLoading]">
                                            <i class="fi fi-rr-eye"></i>
                                            View Truck Loading Parchi
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="8">
                                        <pagination-controls id="stock-pagination" (pageChange)="page = $event;getStockData();"></pagination-controls>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>