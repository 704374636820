<div class="card">
  <div class="card-header d-flex justify-content-center align-items-center">
    <h4>All Taula Parchi</h4>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col">
        <div class="form-group">
          <label for="fromDate">From</label>
          <input type="date" class="form-control" name="fromDate" id="fromDate" aria-describedby="fromdatehelpid"
            placeholder="" />
          <small id="fromdatehelpid" class="form-text text-muted">From Date</small>
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <label for="toDate">To</label>
          <input type="date" class="form-control" name="toDate" id="toDate" aria-describedby="todatehelpid"
            placeholder="" />
          <small id="todatehelpid" class="form-text text-muted">To Date</small>
        </div>
      </div>

      <div class="col">
        <button type="button" class="btn btn-primary">Submit</button>
      </div>
    </div>

    <div class="card bg-white mt-4">
      <div class="card-header">
        <h4 class="mb-0">Taula Parchis</h4>
      </div>
      <div class="card-body">
        <div class="filter-sections">
          <div class="row mb-2">
            <div class="col">
              <div class="form-group">
                <label for="farmerNameSearch">Farmer Name</label>
                <input type="text" class="form-control" name="farmerNameSearch" id="farmerNameSearch"
                  [(ngModel)]="farmerNameSearch" (ngModelChange)="getTaulaParchisFilter()" />
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="farmerMobileSearch">Farmer Mobile</label>
                <input type="text" class="form-control" name="farmerMobileSearch" id="farmerMobileSearch"
                  [(ngModel)]="farmerMobileSearch" (ngModelChange)="getTaulaParchisFilter()" />
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="farmerVillageSearch">Farmer Village</label>
                <input type="text" class="form-control" name="farmerVillageSearch" id="farmerVillageSearch"
                  [(ngModel)]="farmerVillageSearch" (ngModelChange)="getTaulaParchisFilter()" />
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="cropSearch">Crop</label>
                <select class="form-select" name="cropSearch" id="cropSearch" [(ngModel)]="selectedCrop"
                  (ngModelChange)="getTaulaParchisFilter()">
                  <option value="">Select</option>
                  <option *ngFor="let crop of crops" [value]="crop._id">{{ crop.name }}</option>
                </select>
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="snoSearch">S.no</label>
                <input type="text" class="form-control" name="snoSearch" id="snoSearch"
                  [(ngModel)]="snoSearch" (ngModelChange)="getTaulaParchisFilter()" />
              </div>
            </div>
          </div>
        </div>
        <div class="table table-responsive">
          <table class="table table-bordered">
            <thead class="thead-dark">
              <tr>
                <!-- <th scope="col">Sr.no</th> -->
                <th scope="col">Date</th>
                <th scope="col">Purchase Status</th>
                <th scope="col">Farmer's Name</th>
                <th scope="col">Storage Location</th>
                <th scope="col">Crop</th>
                <th scope="col">Firm/Company</th>
                <th scope="col">Net Weight(in Quintal)</th>
                <th scope="col">Amount (₹)</th>
                <th scope="col">Created By</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              <!-- Iterate through TaulaParchi data -->
              <tr *ngFor="
                  let taulaParchi of TaulaParchi
                    | paginate
                      : {
                          itemsPerPage: 10,
                          currentPage: pageForTaulaParchi,
                          id: 'taulaparchi',
                          totalItems: TaulaParchiCount
                        };
                  let i = index
                ">
                <!-- <td>{{ currentPage * perPage - perPage + (i + 1) }}</td> -->
                <td>{{ taulaParchi.created_at | date : "MM-dd-YYYY hh:mm" }}</td>
                <td>{{ taulaParchi?.purchase }}</td>
                <td>
                  {{ taulaParchi?.farmerName }}
                  <br>
                  <span class="badge bg-primary">
                    <i class="fi fi-rr-mobile"></i> | {{ taulaParchi?.farmerMobile }}
                  </span>
                  <br>
                  <span class="badge bg-primary">
                    <i class="fi fi-rr-marker"></i> | {{ taulaParchi?.farmerVillage }}
                  </span>
                </td>
                <td>{{ taulaParchi?.wearhouseDetails?.name }}</td>
                <td>{{ taulaParchi?.cropDetails?.name }}</td>
                <td>{{ taulaParchi?.companyDetails?.name }}</td>
                <td>{{ taulaParchi.netWeight }}</td>
                <td>{{ taulaParchi.amount | number: '.2' }}</td>
                <td>{{ taulaParchi?.userDetails?.name }}</td>
                <td>
                  <button type="button" class="btn btn-sm btn-primary me-2 mb-2" [routerLink]="['/taul-parchi-view', taulaParchi._id]">
                    <!-- View -->
                    <i class="fi fi-rr-eye"></i>
                  </button>
                  <!-- Pay Button link -->
                  <a class="btn btn-sm btn-primary me-2 mb-2" [routerLink]="['/cashier-payment/taulParchi/'+taulaParchi._id]">
                    <!-- Pay -->
                    Pay
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <pagination-controls id="taulaparchi"
          (pageChange)="pageForTaulaParchi = $event; getTaulaParchis()"></pagination-controls>
      </div>
      <div class="card-body">
        <div class="table table-responsive">
          <table class="table table-bordered">
            <thead class="thead-dark">
              <tr>
                <!-- <th scope="col">Sr.no</th> -->
                <th scope="col">Date</th>
                <th scope="col">Purchase Status</th>
                <th scope="col">Farmer's Name</th>
                <th scope="col">Storage Location</th>
                <th scope="col">Crop</th>
                <th scope="col">Firm/Company</th>
                <th scope="col">Net Weight(in Quintal)</th>
                <th scope="col">Amount (₹)</th>
                <th scope="col">Created By</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              <!-- Iterate through TaulaParchi data -->
              <tr *ngFor="
                  let taulaParchi of TaulaParchi
                    | paginate
                      : {
                          itemsPerPage: perPage,
                          currentPage: currentPage,
                          id: 'taulaparchi',
                          totalItems: TaulaParchiCount
                        };
                  let i = index
                ">
                <!-- <td>{{ currentPage * perPage - perPage + (i + 1) }}</td> -->
                <td>{{ taulaParchi.created_at | date : "MM-dd-YYYY hh:mm" }}</td>
                <td>{{ taulaParchi?.purchase }}</td>
                <td>
                  {{ taulaParchi?.farmerName }}
                  <br>
                  <span class="badge bg-primary">
                    <i class="fi fi-rr-mobile"></i> | {{ taulaParchi?.farmerMobile }}
                  </span>
                  <br>
                  <span class="badge bg-primary">
                    <i class="fi fi-rr-marker"></i> | {{ taulaParchi?.farmerVillage }}
                  </span>
                </td>
                <td>{{ taulaParchi?.wearhouseDetails?.name }}</td>
                <td>{{ taulaParchi?.cropDetails?.name }}</td>
                <td>{{ taulaParchi?.companyDetails?.name }}</td>
                <td>{{ taulaParchi.netWeight }}</td>
                <td>{{ taulaParchi.amount | number: '.2' }}</td>
                <td>{{ taulaParchi?.userDetails?.name }}</td>
                <td>
                  <button type="button" class="btn btn-sm btn-primary me-2 mb-2" (click)="printReceipt(taulaParchi)">
                    <!-- Print -->
                    <i class="fi fi-rr-print"></i>
                  </button>
                  <button type="button" class="btn btn-sm btn-primary me-2 mb-2" [routerLink]="['/taul-parchi-view', taulaParchi._id]">
                    <!-- View -->
                    <i class="fi fi-rr-eye"></i>
                  </button>
                  <!-- delete -->
                  <button type="button" class="btn btn-sm btn-danger me-2 mb-2" (click)="deleteTaulaParchi(taulaParchi._id)">
                    <i class="fi fi-rr-trash"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <pagination-controls id="taulaparchi"
          (pageChange)="currentPage = $event; getTaulaParchis()"></pagination-controls>
      </div>
    </div>
  </div>
</div>