<div class="row">
  <div class="col-12 col-md">
    <div class="row">
      <div class="col">
        <div class="card bg-white">
          <div class="card-header text-center">
            <h4 class="mb-0">Add Transaction</h4>

            <div class="row">
              <div class="col text-start">
                <b>
                  <span class="text-muted ps-2">Transaction ID:</span>
                  <!-- <span class="ps-2 text-link">{{ transaction.id }}</span> -->
                </b>
              </div>
              <div class="col text-end"></div>
            </div>
          </div>
          <div class="card-body bg-warning">
            <div class="row">
              <div class="col-12 col-md-6 col-lg-4">
                <div class="border-bottom border-dark border-bottom-dotted mb-2 pb-2">
                  <h6 class="card-subtitle mb-2 text-muted">Farmer's Name:</h6>
                  <p class="card-text">{{ taulaParchi?.farmerName ? taulaParchi.farmerName : 'N/A' }}</p>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="border-bottom border-dark border-bottom-dotted mb-2 pb-2">
                  <h6 class="card-subtitle mb-2 text-muted">Mobile:</h6>
                  <p class="card-text">{{ taulaParchi?.farmerMobile ? taulaParchi.farmerMobile : 'N/A' }}</p>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="border-bottom border-dark border-bottom-dotted mb-2 pb-2">
                  <h6 class="card-subtitle mb-2 text-muted">Village:</h6>
                  <p class="card-text">{{ taulaParchi?.farmerVillage ? taulaParchi.farmerVillage : 'N/A' }}</p>
                </div>
              </div>
            </div>
      
            <div class="row">
              <div class="col-12 col-md-6 col-lg-4">
                <div class="border-bottom border-dark border-bottom-dotted mb-2 pb-2">
                  <h6 class="card-subtitle mb-2 text-muted">Purchase Status:</h6>
                  <p class="card-text">
                    {{ taulaParchi?.purchase == 'directPurchase' ? 'Direct Purchase' : taulaParchi?.purchase ==
                    'AuctionMandiPurchase' ? 'Auction Mandi Purchase' : 'N/A' }}
                  </p>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="border-bottom border-dark border-bottom-dotted mb-2 pb-2">
                  <h6 class="card-subtitle mb-2 text-muted">Crop:</h6>
                  <p class="card-text">{{ taulaParchi?.cropDetails?.name ? taulaParchi.cropDetails.name : 'N/A' }}</p>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <div class="border-bottom border-dark border-bottom-dotted mb-2 pb-2">
                  <h6 class="card-subtitle mb-2 text-muted">Firm/Company:</h6>
                  <p class="card-text">{{ taulaParchi?.companyDetails?.name ? taulaParchi.companyDetails?.name : 'N/A' }}</p>
                </div>
              </div>
            </div>
            <div class="border-bottom border-dark border-bottom-dotted mb-2 pb-2">
              <h6 class="card-subtitle mb-2 text-muted">Rate (Per Quintal):</h6>
              <p class="card-text">{{ taulaParchi?.rate ? '₹' + taulaParchi.rate : 'N/A' }}</p>
            </div>
      
            <div class="border-bottom border-dark border-bottom-dotted mb-2 pb-2">
              <h6 class="card-subtitle mb-2 text-muted">Tulai Options:</h6>
              <p class="card-text">{{ taulaParchi?.tulai ? taulaParchi.tulai : 'N/A' }}</p>
            </div>
      
            <!-- Exempt Hammali field -->
            <div class="border-bottom border-dark border-bottom-dotted mb-2 pb-2" *ngIf="taulaParchi?.tulai === 'Labour'">
              <h6 class="card-subtitle mb-2 text-muted">Exempt Hammali:</h6>
              <p class="card-text">
                {{ taulaParchi?.exemptHammali ? (taulaParchi.exemptHammali === 'deduct' ? 'Deduct' : 'Exempted') : 'N/A' }}
              </p>
            </div>
      
            <div class="border-bottom border-dark border-bottom-dotted mb-2 pb-2">
              <h6 class="card-subtitle mb-2 text-muted">Bora Quantity:</h6>
              <p class="card-text">{{ taulaParchi?.boraQuantity ? taulaParchi.boraQuantity : 'N/A' }}</p>
            </div>
      
            <!-- Bharti field -->
            <div class="border-bottom border-dark border-bottom-dotted mb-2 pb-2">
              <h6 class="card-subtitle mb-2 text-muted">Bharti (in Kgs):</h6>
              <p class="card-text">{{ taulaParchi?.bharti ? taulaParchi.bharti : 'N/A' }}</p>
            </div>
      
            <div class="border-bottom border-dark border-bottom-dotted mb-2 pb-2">
              <h6 class="card-subtitle mb-2 text-muted">Loose Quantity (in Kgs):</h6>
              <p class="card-text">{{ taulaParchi?.looseQuantity ? taulaParchi.looseQuantity : 'N/A' }}</p>
            </div>
            <div class="border-bottom border-dark border-bottom-dotted mb-2 pb-2">
              <h6 class="card-subtitle mb-2 text-muted">Hammali (in ₹):</h6>
              <p class="card-text">{{ taulaParchi?.hammali ? taulaParchi.hammali : 'N/A' }}</p>
            </div>
            <div class="border-bottom border-dark border-bottom-dotted mb-2 pb-2">
              <h6 class="card-subtitle mb-2 text-muted">Net Weight (in Quintal):</h6>
              <p class="card-text">{{ taulaParchi?.netWeight }}</p>
            </div>
      
            <div class="border-bottom border-dark border-bottom-dotted mb-2 pb-2">
              <h6 class="card-subtitle mb-2 text-muted">Hammal:</h6>
              <p class="card-text">{{ taulaParchi?.hammalDetails?.name ? taulaParchi.hammalDetails.name : 'N/A' }}</p>
            </div>
      
            <div class="border-bottom border-dark border-bottom-dotted mb-2 pb-2">
              <h6 class="card-subtitle mb-2 text-muted">Storage Location:</h6>
              <p class="card-text">{{ taulaParchi?.wearhouseDetails?.name ? taulaParchi.wearhouseDetails.name : 'N/A' }}</p>
            </div>
      
            <div class="border-bottom border-dark border-bottom-dotted mb-2 pb-2">
              <h6 class="card-subtitle mb-2 text-muted">Amount (in ₹):</h6>
              <p class="card-text">{{ taulaParchi?.amount ? '₹' + taulaParchi.amount : 'N/A' }}</p>
            </div>
          </div>
          <div class="border-bottom border-dark border-bottom-dotted mb-2 pb-2 bg-warning text-dark">
            <h6 class="card-subtitle mb-2 text-muted bg-warning text-dark">Transaction Type</h6>
            <div class="form-group bg-warning">
              <select id="transactionType" class="form-select bg-warning text-dark" [(ngModel)]="updateTaulparchi.transactionType" name="transactionType">
                <option value="">Select Transaction Type</option>
                <option value="cash">Cash</option>
                <option value="credit">Credit</option>
                <option value="banktransfer">Bank Transfer</option>
              </select>
            </div>
          </div>
          
          
          <div class="card-actions bg-light border">
            <div class="text-end">
              <button
                class="btn btn-primary btn-lg m-3"
                (click)="saveParchi()"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
