<div class="card bg-white p-3">
    <div class="profile-container">
        <div class="profile-header">
            <h1>User Profile</h1>
        </div>
        <hr>
        <div class="profile-content" *ngIf="currentUser">
            <!-- <div class="profile-picture d-none d-lg-block">
                <img
                [src]="currentUser['Role Name'] == 'Student' ? 'assets/logos/undraw_Exams_re_4ios 1.png': (currentUser['Role Name'] == 'Guardian' ? 'assets/logos/undraw_Exams_re_4ios 1.png' : (currentUser['Role Name'] == 'Teacher' ? 'assets/logos/undraw_Teacher_re_sico 1.png' : 'assets/logos/undraw_Exams_re_4ios 1.png'))"
                alt="Profile Picture">
            </div> -->
            <div class="profile-details">
                <h2>{{ currentUser.name }}</h2>
                <div class="row">
                    <div class="col-12 col-lg-6" *ngFor="let key of getKeys()">
                        <p class="border rounded m-2 p-2 px-3">
                            <strong>{{ key }}:</strong> {{ currentUser[key] }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!currentUser">
            <!-- Bootstrap FontAwesome Loader -->
            <div class="d-flex justify-content-center">
                <div class="spinner-border text-primary" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
        </div>
    </div>
</div>
