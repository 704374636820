<!-- Modal for Add/Update Storage -->
<div class="modal fade" [ngClass]="{'show': isEdit}" id="addUpdateStorageModal" tabindex="-1"
    aria-labelledby="addUpdateStorageModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="addUpdateStorageModalLabel">{{ isEdit == 'Update' ? 'Update' : 'Add' }}
                    {{masterName}}</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                    (click)="isEdit=undefined"></button>
            </div>
            <div class="modal-body">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <label for="storageName" class="form-label">Name</label>
                                <input id="storageName" class="form-control" [(ngModel)]="masterToAddOrEdit.name"
                                    name="storageName" type="text" placeholder="Name" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                    (click)="isEdit=undefined">Close</button>
                <button *ngIf="isEdit == 'Add'" type="button" class="btn btn-primary" (click)="addNewItem()">
                    Add
                </button>
                <button *ngIf="isEdit == 'Update'" type="button" class="btn btn-primary"
                    (click)="editItem(masterToAddOrEditIndex)">
                    Update
                </button>
            </div>
        </div>
    </div>
</div>

<!-- Stock Modal -->
<div class="modal fade" [ngClass]="{'show': isStockUpdateModelOpen}" id="addUpdateStockModal" tabindex="-1"
    aria-labelledby="addUpdateStockModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="addUpdateStockModalLabel">Update Stock</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                    (click)="isStockUpdateModelOpen=false"></button>
            </div>
            <div class="modal-body">
                <div class="container">
                    <!-- Form:
                    stockItemToAddOrEdit.crop: crops dropdown,
                    stockItemToAddOrEdit.quantity: in quintals,
                    stockItemToAddOrEdit.warehouse: warehouses dropdown,
                    stockItemToAddOrEdit.price: in ₹,
                    stockItemToAddOrEdit.logType: 'purchase', 'sale', 'transfer in', 'transfer out',
                    stockItemToAddOrEdit.meta_data.remark: text,
                    -->
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group mb-3">
                                <label for="crop" class="form-label">Crop</label>
                                <select id="crop" class="form-select" [(ngModel)]="stockItemToAddOrEdit.crop"
                                    name="crop">
                                    <option value="">Select Crop</option>
                                    <option *ngFor="let crop of crops" [value]="crop._id">{{ crop.name }}</option>
                                </select>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="form-group mb-3">
                                        <label for="no_of_bags" class="form-label">No of Bags</label>
                                        <input id="no_of_bags" class="form-control" name="no_of_bags"
                                            type="number" placeholder="No of Bags" />
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group mb-3">
                                        <label for="unit_weight_of_bags" class="form-label">Unit Weight of Bags (in Kgs)</label>
                                        <input id="unit_weight_of_bags" class="form-control" name="unit_weight_of_bags"
                                            type="number" placeholder="Unit Weight of Bags" />
                                    </div>
                                </div>
                                <div class="col-auto">
                                    <div class="form-group mb-3">
                                        <label for="add_button" class="form-label">Add</label>
                                        <button id="add_button" class="btn btn-primary w-100" (click)="addBags()">
                                            Add
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <table class="table table-hover table-striped">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>No of Bags</th>
                                        <th>Weight (in Kgs)</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let bag of stockItemToAddOrEdit.bag_units; let i = index">
                                        <td>{{ i + 1 }}</td>
                                        <td>{{ bag.no_of_bags }}</td>
                                        <td>{{ bag.unit_weight_of_bags }}</td>
                                        <td>
                                            <button class="btn btn-outline-danger btn-sm" (click)="removeBag(i)">
                                                <i class="fi fi-rr-cross"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="form-group mb-3">
                                <label for="quantity" class="form-label">Quantity (In Quintals)</label>
                                <input id="quantity" class="form-control" [(ngModel)]="stockItemToAddOrEdit.quantity"
                                    name="quantity" type="number" placeholder="Quantity" readonly/>
                            </div>
                            <div class="form-group mb-3">
                                <label for="warehouse" class="form-label">
                                    Warehouse (Storage Location)
                                </label>
                                <select id="warehouse" class="form-select" [(ngModel)]="stockItemToAddOrEdit.warehouse"
                                    name="warehouse">
                                    <option value="">Select Warehouse</option>
                                    <option *ngFor="let warehouse of data" [value]="warehouse._id">
                                        {{ warehouse.name }}
                                    </option>
                                </select>
                            </div>
                            <div class="form-group mb-3">
                                <label for="price" class="form-label">Rate (in ₹)</label>
                                <input id="price" class="form-control" [(ngModel)]="stockItemToAddOrEdit.price"
                                    name="price" type="number" placeholder="Price" />
                            </div>
                            <div class="form-group mb-3">
                                <label for="logType" class="form-label">Log Type</label>
                                <select id="logType" class="form-select" [(ngModel)]="stockItemToAddOrEdit.logType"
                                    name="logType">
                                    <option value="">Select Log Type</option>
                                    <option value="purchase">Purchase</option>
                                    <option value="sale">Sale</option>
                                    <option value="transfer in">Transfer In</option>
                                    <option value="transfer out">Transfer Out</option>
                                </select>
                            </div>
                            <div class="form-group mb-3">
                                <label for="remark" class="form-label">Remark</label>
                                <textarea id="remark" class="form-control"
                                    [(ngModel)]="stockItemToAddOrEdit.meta_data.remark" name="remark"
                                    placeholder="Remark"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                    (click)="isStockUpdateModelOpen=false">Close</button>
                <button type="button" class="btn btn-primary" (click)="updateStock()">Update</button>
            </div>
        </div>
    </div>
</div>

<!-- Storage Management -->
<div class="row">
    <div class="col-12 col-md">
        <div class="row">
            <div class="col">
                <!-- Storage Management Card -->
                <div class="card bg-white">
                    <div class="card-header">
                        <div class="d-flex justify-content-center align-items-center">
                            <h2>
                                {{masterName}} Management
                            </h2>
                        </div>
                    </div>
                    <div class="card-body">
                        <!-- Storage Items -->
                        <div class="container">
                            <!-- Add Storage Button -->
                            <div class="row">
                                <div class="col-12">
                                    <div class="d-flex flex-column flex-md-row justify-content-between">
                                        <div>
                                            <button class="btn btn-primary w-100 w-md-auto" data-bs-toggle="modal"
                                                data-bs-target="#addUpdateStorageModal" (click)="selectItemToAdd()">
                                                <i class="fi fi-rr-add"></i>
                                                <span class="ms-2">
                                                    Add {{masterName}}
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-12">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <!-- Pagination Controls -->
                                        <pagination-controls id="storage_list" (pageChange)="p = $event; getData()">
                                        </pagination-controls>
                                    </div>
                                    <div class="table-responsive">
                                        <table class="table table-hover table-striped">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Name</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    *ngFor="let item of data | paginate: { id: 'storage_list', itemsPerPage: limit, currentPage: p, totalItems: total }; let i = index">
                                                    <td>{{ i + 1 }}</td>
                                                    <td>{{ item.name }}</td>
                                                    <td>
                                                        <!-- View Stock -->
                                                        <button class="btn btn-outline-success btn-sm"
                                                            (click)="viewStock(item._id)">
                                                            <i class="fi fi-rr-eye"></i>
                                                            View Stock
                                                        </button>
                                                        <button class="btn btn-outline-primary btn-sm"
                                                            (click)="selectItemToEdit(i)">
                                                            <i class="fi fi-rr-pencil"></i>
                                                        </button>
                                                        <button class="btn btn-outline-danger btn-sm"
                                                            (click)="deleteItem(i)">
                                                            <i class="fi fi-rr-trash"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Stock Table -->
<!-- crop, quantity, averagePrice -->
<div class="row mt-3" *ngIf="selectedWarehouseId">
    <div class="col-12">
        <div class="card bg-white">
            <div class="card-header">
                <div class="d-flex justify-content-center align-items-center">
                    <h2>
                        Stock Management
                        ({{ getSelectedWarehouse().name }})
                    </h2>
                </div>
            </div>
            <div class="card-body">
                <section id="operations">
                    <!-- Export to excel -->
                    <div class="container">
                        <div class="row">
                            <div class="col-12">
                                <div class="d-flex justify-content-end">
                                    <button class="btn btn-primary" (click)="exportToExcel()"
                                        [disabled]="!stockData.length">
                                        <i class="fi fi-rr-file-excel"></i>
                                        Export to Excel
                                    </button>
                                    <!-- Update Stock -->
                                    <button class="btn btn-primary ms-2" data-bs-toggle="modal"
                                        data-bs-target="#addUpdateStockModal" (click)="selectStockItemToAdd()">
                                        <i class="fi fi-rr-add"></i>
                                        <span class="ms-2">
                                            Update Stock
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div class="row mt-3" *ngIf="selectedWarehouseId">
                    <div class="row">
                        <div class="col border-end" *ngFor="let crop of crops">
                            <a class="btn btn-outline-primary" [routerLink]="['/stock-view/'+crop._id+'/'+selectedWarehouseId]">
                                <div class="row">
                                    <div class="col">
                                        {{ crop.name }}
                                    </div>
                                    <div class="col-auto">
                                        (pending: {{ getCropPendingCount(crop._id) }})
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="row mt-3" *ngIf="selectedWarehouseId && !stockData.length">
                    <div class="alert bg-warning">
                        No Crops are available in stock
                    </div>
                </div>
                <div *ngIf="selectedWarehouseId && stockData.length">
                    <div class="container">
                        <div class="row">
                            <div class="col-12">
                                <div class="table-responsive">
                                    <table class="table table-hover table-striped">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Crop</th>
                                                <th>Quantity (In Quintals)</th>
                                                <th>Average Price (in ₹)</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of stockData; let i = index">
                                                <td>{{ i + 1 }}</td>
                                                <td>{{ item.crop }}</td>
                                                <td>{{ item.quantity }}</td>
                                                <td>{{ item.averagePrice | number:'0.2' }}</td>
                                                <td>
                                                    <a class="btn btn-outline-primary btn-sm"
                                                        [routerLink]="['/stock-view/'+item.crop_id+'/'+selectedWarehouseId]">
                                                        <i class="fi fi-rr-eye"></i>
                                                        View
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>