<div class="container mt-2">
  <div class="card bg-white mb-3">
    <div class="card-header">
      <div class="text-center">
        <h4 class="mb-0">
          Taul Parchi
          <!-- Print -->
          <button type="button" class="btn btn-primary" (click)="printReceipt(taulaParchi)">
            <i class="fas fa-print"></i> Print
          </button>
        </h4>
        <p><b>M+M</b></p>
      </div>
      <h6 class="card-title">
        <div class="row">
          <div class="col text-start">
            <!-- SRNO. TL{DD}{MM}{YY}{HH}{MM}{SS} -->
            <!-- <b>Sr.no:</b> {{ getTaulaParchiSrNo(taulaParchi?.created_at) }} -->
            <b>Sr.no:{{ taulaParchi?.sno }}</b>
          </div>
          <div class="col text-end">
            <b>Date:</b> {{ taulaParchi?.created_at | date: 'dd-MM-YYYY' }}
            <span class="ms-2">
              <b>Time:</b> {{ taulaParchi?.created_at | date: 'HH:mm:ss' }}
            </span>
          </div>
        </div>
      </h6>
    </div>


    <div class="card-body bg-warning">
      <div class="row">
        <div class="col-12 col-md-6 col-lg-4">
          <div class="border-bottom border-dark border-bottom-dotted mb-2 pb-2">
            <h6 class="card-subtitle mb-2 text-muted">Farmer's Name:</h6>
            <p class="card-text">{{ taulaParchi?.farmerName ? taulaParchi.farmerName : 'N/A' }}</p>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="border-bottom border-dark border-bottom-dotted mb-2 pb-2">
            <h6 class="card-subtitle mb-2 text-muted">Mobile:</h6>
            <p class="card-text">{{ taulaParchi?.farmerMobile ? taulaParchi.farmerMobile : 'N/A' }}</p>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="border-bottom border-dark border-bottom-dotted mb-2 pb-2">
            <h6 class="card-subtitle mb-2 text-muted">Village:</h6>
            <p class="card-text">{{ taulaParchi?.farmerVillage ? taulaParchi.farmerVillage : 'N/A' }}</p>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-6 col-lg-4">
          <div class="border-bottom border-dark border-bottom-dotted mb-2 pb-2">
            <h6 class="card-subtitle mb-2 text-muted">Purchase Status:</h6>
            <p class="card-text">
              {{ taulaParchi?.purchase == 'directPurchase' ? 'Direct Purchase' : taulaParchi?.purchase ==
              'AuctionMandiPurchase' ? 'Auction Mandi Purchase' : 'N/A' }}
            </p>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="border-bottom border-dark border-bottom-dotted mb-2 pb-2">
            <h6 class="card-subtitle mb-2 text-muted">Crop:</h6>
            <p class="card-text">{{ taulaParchi?.cropDetails?.name ? taulaParchi.cropDetails.name : 'N/A' }}</p>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="border-bottom border-dark border-bottom-dotted mb-2 pb-2">
            <h6 class="card-subtitle mb-2 text-muted">Firm/Company:</h6>
            <p class="card-text">{{ taulaParchi?.companyDetails?.name ? taulaParchi.companyDetails?.name : 'N/A' }}</p>
          </div>
        </div>
      </div>
      <div class="border-bottom border-dark border-bottom-dotted mb-2 pb-2">
        <h6 class="card-subtitle mb-2 text-muted">Rate (Per Quintal):</h6>
        <p class="card-text">{{ taulaParchi?.rate ? '₹' + taulaParchi.rate : 'N/A' }}</p>
      </div>

      <div class="border-bottom border-dark border-bottom-dotted mb-2 pb-2">
        <h6 class="card-subtitle mb-2 text-muted">Tulai Options:</h6>
        <p class="card-text">{{ taulaParchi?.tulai ? taulaParchi.tulai : 'N/A' }}</p>
      </div>

      <!-- Exempt Hammali field -->
      <div class="border-bottom border-dark border-bottom-dotted mb-2 pb-2" *ngIf="taulaParchi?.tulai === 'Labour'">
        <h6 class="card-subtitle mb-2 text-muted">Exempt Hammali:</h6>
        <p class="card-text">
          {{ taulaParchi?.exemptHammali ? (taulaParchi.exemptHammali === 'deduct' ? 'Deduct' : 'Exempted') : 'N/A' }}
        </p>
      </div>

      <div class="border-bottom border-dark border-bottom-dotted mb-2 pb-2">
        <h6 class="card-subtitle mb-2 text-muted">Bora Quantity:</h6>
        <p class="card-text">{{ taulaParchi?.boraQuantity ? taulaParchi.boraQuantity : 'N/A' }}</p>
      </div>

      <!-- Bharti field -->
      <div class="border-bottom border-dark border-bottom-dotted mb-2 pb-2">
        <h6 class="card-subtitle mb-2 text-muted">Bharti (in Kgs):</h6>
        <p class="card-text">{{ taulaParchi?.bharti ? taulaParchi.bharti : 'N/A' }}</p>
      </div>

      <div class="border-bottom border-dark border-bottom-dotted mb-2 pb-2">
        <h6 class="card-subtitle mb-2 text-muted">Loose Quantity (in Kgs):</h6>
        <p class="card-text">{{ taulaParchi?.looseQuantity ? taulaParchi.looseQuantity : 'N/A' }}</p>
      </div>
      <div class="border-bottom border-dark border-bottom-dotted mb-2 pb-2">
        <h6 class="card-subtitle mb-2 text-muted">Hammali (in ₹):</h6>
        <p class="card-text">{{ taulaParchi?.hammali ? taulaParchi.hammali : 'N/A' }}</p>
      </div>
      <div class="border-bottom border-dark border-bottom-dotted mb-2 pb-2">
        <h6 class="card-subtitle mb-2 text-muted">Net Weight (in Quintal):</h6>
        <p class="card-text">{{ taulaParchi?.netWeight }}</p>
      </div>

      <div class="border-bottom border-dark border-bottom-dotted mb-2 pb-2">
        <h6 class="card-subtitle mb-2 text-muted">Hammal:</h6>
        <p class="card-text">{{ taulaParchi?.hammalDetails?.name ? taulaParchi.hammalDetails.name : 'N/A' }}</p>
      </div>

      <div class="border-bottom border-dark border-bottom-dotted mb-2 pb-2">
        <h6 class="card-subtitle mb-2 text-muted">Storage Location:</h6>
        <p class="card-text">{{ taulaParchi?.wearhouseDetails?.name ? taulaParchi.wearhouseDetails.name : 'N/A' }}</p>
      </div>

      <div class="border-bottom border-dark border-bottom-dotted mb-2 pb-2">
        <h6 class="card-subtitle mb-2 text-muted">Amount (in ₹):</h6>
        <p class="card-text">{{ taulaParchi?.amount ? '₹' + taulaParchi.amount : 'N/A' }}</p>
      </div>
    </div>
  </div>

  <!-- Back Button -->
  <div class="text-center mt-4">
    <button type="button" class="btn btn-secondary px-5" (click)="goBack()">
      <i class="fas fa-arrow-left"></i> Back
    </button>
  </div>
</div>