<div class="row">
  <div class="col-12 col-md">
    <div class="row">
      <div class="col">
        <div class="card bg-white">
          <div class="card-header text-center">
            <h4 class="mb-0">Truck Loading Parchi</h4>
            <p>
              <b>M+M</b>
            </p>
            <div class="row">
              <div class="col text-start">
                <b>
                  <span class="text-muted ps-2">Serial Number:</span>
                  <span class="text-link">{{ TruckLoadingParchi.id }}</span>
                </b>
              </div>
              <div class="col text-end">
                <b>
                  <span class="text-muted pe-2">Date:</span>
                  <span class="text-link">{{
                    TruckLoadingParchi.created_at | date : "dd-MM-YYYY"
                  }}</span>
                </b>
                <b>
                  <span class="text-muted ps-2">Time:</span>
                  <span class="text-link">{{
                    TruckLoadingParchi.created_at | date : "hh:mm"
                  }}</span>
                </b>
              </div>
            </div>
          </div>
          <div class="card-body bg-warning">
            <div class="row">
              <div class="col-12">
                <div class="form-group mb-3">
                  <!-- transferType: {
                    type: String,
                    enum: ['Sale', 'Stock Transfer']
                }, -->
                  <label for="transferType" class="form-label mb-0">Transfer Type</label>
                  <select
                    id="transferType"
                    class="form-select"
                    [(ngModel)]="TruckLoadingParchi.transferType"
                    name="transferType"
                  >
                    <option value="">Select Transfer Type</option>
                    <option value="Sale">Sale</option>
                    <option value="Stock Transfer">Stock Transfer</option>
                  </select>
                </div>
                <div class="form-group mb-3">
                  <label for="partyName" class="form-label">Party Name</label>
                  <!-- <select
                    id="partyName"
                    class="form-select"
                    [(ngModel)]="TruckLoadingParchi.partyName"
                    name="partyName"
                  >
                    <option value="">Select Party</option>
                    <option *ngFor="let party of Parties" [value]="party._id">
                      {{ party.name }}
                    </option>
                  </select> -->
                  <!-- convert to p-dropdown according to above select -->
                  <p-dropdown
                    [options]="Parties"
                    [(ngModel)]="TruckLoadingParchi.partyName"
                    optionLabel="name"
                    placeholder="Select Party"
                    optionValue="_id"
                    [style]="{ width: '100%' }"
                    [showClear]="true" [filter]="true"
                  ></p-dropdown>

                  <label for="crop" class="form-label">Crop</label>
                  <!-- <select
                    id="crop"
                    class="form-select"
                    [(ngModel)]="TruckLoadingParchi.crop"
                    name="crop"
                  >
                    <option value="">Select Crop</option>
                    <option *ngFor="let crop of Crops" [value]="crop._id">
                      {{ crop.name }}
                    </option>
                  </select> -->
                  <p-dropdown
                    [options]="Crops"
                    [(ngModel)]="TruckLoadingParchi.crop"
                    optionLabel="name"
                    placeholder="Select Crop"
                    optionValue="_id"
                    [style]="{ width: '100%' }"
                    [showClear]="true" [filter]="true"
                  ></p-dropdown>

                  <label for="transport" class="form-label">Transport Name</label>
                  <!-- <select
                    id="transport"
                    class="form-select"
                    [(ngModel)]="TruckLoadingParchi.transport"
                    name="transport"
                  >
                    <option value="">Select Transport</option>
                    <option *ngFor="let transport of Transport" [value]="transport._id">
                      {{ transport.name }}
                    </option>
                  </select> -->
                  <p-dropdown
                    [options]="Transport"
                    [(ngModel)]="TruckLoadingParchi.transport"
                    optionLabel="name"
                    placeholder="Select Transport"
                    optionValue="_id"
                    [style]="{ width: '100%' }"
                    [showClear]="true" [filter]="true"
                  ></p-dropdown>

                  <!-- <label for="vehicleNumber" class="form-label
                                    ">Vehicle Number</label>
                                    <input id="vehicleNumber" class="form-control" [(ngModel)]="TruckLoadingParchi.vehicleNumber"
                                        name="vehicleNumber" type="text" placeholder="Vehicle Number" /> -->
                  <label for="truck" class="form-label">Select Truck</label>
                  <!-- <select
                    id="truck"
                    class="form-select"
                    [(ngModel)]="TruckLoadingParchi.truck"
                    name="truck"
                  >
                    <option value="">Select Truck</option>
                    <option *ngFor="let truck of Trucks" [value]="truck._id">
                      {{ truck.truckNumber }}
                    </option>
                  </select> -->
                  <p-dropdown
                    [options]="Trucks"
                    [(ngModel)]="TruckLoadingParchi.truck"
                    optionLabel="truckNumber"
                    placeholder="Select Truck"
                    optionValue="_id"
                    [style]="{ width: '100%' }"
                    [showClear]="true" [filter]="true"
                  ></p-dropdown>

                  <label for="assignedHammal" class="form-label"
                    >Assigned Hammal</label
                  >
                  <!-- <select
                    id="assignedHammal"
                    class="form-select"
                    [(ngModel)]="TruckLoadingParchi.assignedHammal"
                    name="assignedHammal"
                  >
                    <option value="">Select Hammal</option>
                    <option *ngFor="let hammal of Hammals" [value]="hammal._id">
                      {{ hammal.name }}
                    </option>
                  </select> -->
                  <p-dropdown
                    [options]="Hammals"
                    [(ngModel)]="TruckLoadingParchi.assignedHammal"
                    optionLabel="name"
                    placeholder="Select Hammal"
                    optionValue="_id"
                    [style]="{ width: '100%' }"
                    [showClear]="true" [filter]="true"
                  ></p-dropdown>


                  <label for="storage" class="form-label"
                    >Storage Location</label
                  >
                  <!-- <select
                    id="storage"
                    class="form-select"
                    [(ngModel)]="TruckLoadingParchi.storage"
                    name="storage"
                  >
                    <option value="">Select Storage Location</option>
                    <option
                      *ngFor="let storage of Storage"
                      [value]="storage._id"
                    >
                      {{ storage.name }}
                    </option>
                  </select> -->
                  <p-dropdown
                    [options]="Storage"
                    [(ngModel)]="TruckLoadingParchi.storage"
                    optionLabel="name"
                    placeholder="Select Storage Location"
                    optionValue="_id"
                    [style]="{ width: '100%' }"
                    [showClear]="true" [filter]="true"
                  ></p-dropdown>


                  <label for="deliveryLocation" class="form-label"
                    >Delivery Location</label
                  >
                  <!-- <select
                    *ngIf="TruckLoadingParchi.transferType === 'Sale'"
                    id="deliveryLocation"
                    class="form-select"
                    [(ngModel)]="TruckLoadingParchi.deliveryLocation"
                    name="deliveryLocation"
                  >
                    <option value="">Select Location</option>
                    <option
                      *ngFor="let location of DeliveryLocations"
                      [value]="location._id"
                    >
                      {{ location.name }}
                    </option>
                  </select> -->
                  <p-dropdown
                    *ngIf="TruckLoadingParchi.transferType === 'Sale'"
                    [options]="DeliveryLocations"
                    [(ngModel)]="TruckLoadingParchi.deliveryLocation"
                    optionLabel="name"
                    placeholder="Select Delivery Location"
                    optionValue="_id"
                    [style]="{ width: '100%' }"
                    [showClear]="true" [filter]="true"
                  ></p-dropdown>

                  <!-- <select *ngIf="TruckLoadingParchi.transferType === 'Stock Transfer'" id="deliveryLocation" class="form-select" [(ngModel)]="TruckLoadingParchi.deliveryLocation" name="deliveryLocation">
                    <option value="">Select Location</option>
                    <option *ngFor="let location of Storage" [value]="location._id">
                      {{ location.name }}
                    </option>
                  </select> -->
                  <p-dropdown
                    *ngIf="TruckLoadingParchi.transferType === 'Stock Transfer'"
                    [options]="Storage"
                    [(ngModel)]="TruckLoadingParchi.deliveryLocation"
                    optionLabel="name"
                    placeholder="Select Delivery Location"
                    optionValue="_id"
                    [style]="{ width: '100%' }"
                    [showClear]="true" [filter]="true"
                  ></p-dropdown>

                  <div>
                    <button class="btn btn-primary mt-3" type="button" (click)="getStockInfo()">
                      Get Stock Information
                    </button>
                  </div>
                  <hr>
                  <!-- Show StockInfo -->
                  <!-- quantity, averagePrice in badges -->
                  <div *ngIf="isStockFetched">
                    <div class="row">
                      <div class="col">
                        <div class="card bg-light">
                          <div class="card-body">
                            <div class="row">
                              <div class="col">
                                <h6 class="card-subtitle mb-2 text-muted">Stock Quantity</h6>
                                <p class="card-text">{{ stockInfo.quantity }}</p>
                              </div>
                              <div class="col">
                                <h6 class="card-subtitle mb-2 text-muted">Average Price</h6>
                                <p class="card-text">
                                  {{ stockInfo.averagePrice | currency: 'INR' }}
                                </p>
                              </div>
                              <div class="col-12">
                                <table class="table table-bordered">
                                  <thead>
                                    <tr>
                                      <th scope="col">#</th>
                                      <th scope="col">No of Bags</th>
                                      <th scope="col">Weight (in Kgs)</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="let bag of stockInfo.bag_units; let i = index">
                                      <th scope="row">{{ i + 1 }}</th>
                                      <td>{{ bag.no_of_bags }}</td>
                                      <td>{{ bag.unit_weight_of_bags }}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  <hr>
                  <div>

                  <div *ngIf="isStockFetched">

                    <label for="unitBora" class="form-label">Bora Bharti (in Kgs)</label>
                    <!-- <input
                      id="unitBora"
                      class="form-control"
                      [(ngModel)]="TruckLoadingParchi.unitBora"
                      name="unitBora"
                      type="number"
                      placeholder="Unit Bora"
                      (ngModelChange)="calculateNetWeight()"
                    /> -->
                    <select
                      id="unitBora"
                      class="form-select"
                      [(ngModel)]="TruckLoadingParchi.unitBora"
                      name="unitBora"
                      (ngModelChange)="calculateNetWeight()"
                    >
                      <option [ngValue]="">Select Bora Bharti</option>
                      <option *ngFor="let unit of stockInfo.bag_units" [ngValue]="unit.unit_weight_of_bags">
                        {{ unit.unit_weight_of_bags }}
                      </option>
                    </select>

                    <div class="form-group" *ngIf="selectedStockInfo">
                      <label for="boraQuantity" class="form-label">
                        Bora Quantity
                      </label>
                      <!-- <input
                        id="boraQuantity"
                        class="form-control"
                        [(ngModel)]="TruckLoadingParchi.boraQuantity"
                        name="boraQuantity"
                        type="number"
                        placeholder="Bora Quantity"
                        (ngModelChange)="calculateNetWeight()"
                      /> -->
                      <select
                        id="boraQuantity"
                        class="form-select"
                        [(ngModel)]="TruckLoadingParchi.boraQuantity"
                        name="boraQuantity"
                        (ngModelChange)="calculateNetWeight()"
                      >
                        <option [ngValue]="">Select Bora Quantity</option>
                        <option *ngFor="let num of getArray(selectedStockInfo.no_of_bags); let i = index" [ngValue]="i + 1">
                          {{ i + 1 }}
                        </option>
                      </select>
                    </div>

                    <label for="bardanaType" class="form-label"
                      >Bardana Type</label
                    >
                    <select
                      id="bardanaType"
                      class="form-select"
                      [(ngModel)]="TruckLoadingParchi.bardanaType"
                      name="bardanaType"
                      (ngModelChange)="calculateNetWeight()"
                    >
                      <option [ngValue]="">Select Bardana Type</option>
                      <option [ngValue]="0">No Bardana</option>
                      <option [ngValue]=650>650g</option>
                      <option [ngValue]=1000>1Kg</option>
                    </select>
                    <!--
                    <div class="form-group" *ngIf="TruckLoadingParchi.bardanaType">
                      <label for="bardanaUnit" class="form-label">Bardana Unit</label>
                      <input
                        id="bardanaUnit"
                        class="form-control"
                        [(ngModel)]="TruckLoadingParchi.bardanaUnit"
                        name="bardanaUnit"
                        type="number"
                        (ngModelChange)="calculateNetWeight()"
                      />
                    </div> -->

                    <!-- <div
                      *ngIf="
                        TruckLoadingParchi.bardanaType === 0.65 ||
                        TruckLoadingParchi.bardanaType === 1
                      "
                    >
                      <label for="bardanaUnit" class="form-label">
                        Bardana Unit
                      </label>
                      <input
                        id="bardanaUnit"
                        class="form-control"
                        [(ngModel)]="TruckLoadingParchi.bardanaUnit"
                        name="bardanaUnit"
                        type="number"
                        step="0.01"
                        (ngModelChange)="calculateNetWeight()"
                        readonly
                      />
                    </div> -->

                    <label for="driedWeight" class="form-label"
                      >Dried Weight(in Kgs)</label>
                    <input id="driedWeight" class="form-control" [(ngModel)]="TruckLoadingParchi.driedWeight" name="driedWeight" type="number" placeholder="Dried Weight" (ngModelChange)="calculateNetWeight()" />

                    <label for="netWeight" class="form-label"
                      >Net Weight(in Quintal)</label
                    >
                    <input
                      id="netWeight"
                      class="form-control"
                      [value]="TruckLoadingParchi.netWeight"
                      name="netWeight"
                      type="number"
                      step="0.01"
                      placeholder="Net Weight"
                      readonly
                    />

                    <label for="rate" class="form-label">Rate(Per Quintal)</label>
                    <input
                      id="rate"
                      class="form-control"
                      [(ngModel)]="TruckLoadingParchi.rate"
                      name="rate"
                      type="text"
                      placeholder="rate"
                    />

                    <!-- <label for="amount" class="form-label">Payable Amount</label>
                    <input
                      id="amount"
                      class="form-control"
                      [value]="TruckLoadingParchi.amount"
                      name="amount"
                      type="number"
                      placeholder="Amount"
                      readonly
                    /> -->
                    <label for="advance" class="form-label">Freight Advance</label>
                    <input
                      id="advance"
                      class="form-control"
                      [(ngModel)]="TruckLoadingParchi.advance"
                      name="advance"
                      type="text"
                      placeholder="Freight Advance"
                    />
                    <label for="other" class="form-label">Remark</label>
                    <input
                      id="other"
                      class="form-control"
                      [(ngModel)]="TruckLoadingParchi.other"
                      name="other"
                      type="text"
                      placeholder="Enter Remark"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card-actions bg-light border">
          <div class="text-end">
            <button
              class="btn btn-primary btn-lg m-3"
              type="button"
              (click)="saveTruckLoadingParchi()"
              [disabled]="!isStockFetched"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
